<template>
  <div>
    <div class="header">
      <div class="header_top">
        <span style="margin: 0">当前位置：</span>
        <span class="center">批量合同</span>
        <img src="../../../assets/right.png"
             alt />
        <span class="center">批量导入列表</span>
      </div>
    </div>
    <div class="top">
      <div style="display: flex; align-items: center; flex-wrap: wrap">
        <div>
          <span class="seach_text">关键字：</span>

          <el-input size="mini"
                    placeholder="请输入合同名称"
                    v-model="formInline.f_like_name"
                    style="margin-right: 10px; width: 250px"
                    prefix-icon="el-icon-search"
                    clearable></el-input>
        </div>
        <div>
          <span class="seach_text">合同分类：</span>
          <el-select size="mini"
                     v-model="formInline.type"
                     style="margin-right: 10px; width: 250px"
                     placeholder="请选择合同分类"
                     clearable>
            <el-option label="全部"
                       value></el-option>
            <el-option label="入职合同"
                       value="1"></el-option>
            <el-option label="离职合同"
                       value="2"></el-option>
            <el-option label="用工合同"
                       value="3"></el-option>
          </el-select>
        </div>
        <el-button size="small"
                   style="margin: 10px; border-radius: 5px"
                   type="primary"
                   @click="seach">查询</el-button>
      </div>
      <div>
        <el-button size="small"
                   @click="getMore"
                   icon="el-icon-plus"
                   type="primary">批量导入</el-button>
      </div>
    </div>
    <el-table :data="tableData"
              style="width: 100%">
      <el-table-column label="批量导入名称">
        <template slot-scope="scope">
          <span>{{scope.row.name}}</span>
        </template>
      </el-table-column>
      <el-table-column label="合同模板">
        <template slot-scope="scope">
          <span>{{scope.row.templateName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="合同类型">
        <template slot-scope="scope">
          <span v-if="scope.row.type==='1'">入职合同</span>
          <span v-if="scope.row.type==='2'">离职合同</span>
          <span v-if="scope.row.type==='3'">用工合同</span>
        </template>
      </el-table-column>
      <el-table-column label="批量导入描述">
        <template slot-scope="scope">
          <span>{{scope.row.desc}}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间">
        <template slot-scope="scope">
          <span>{{scope.row.createTime}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button @click="goDetail({ code: scope.row.code })"
                     type="text">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor"
            style="margin: 0 10px">每页{{ pageSize }}条</span>
      <el-pagination background
                     :current-page.sync="pageNumber"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :page-size="pageSize"
                     prev-text="上一页"
                     next-text="下一页"
                     layout="prev,pager, next,jumper"
                     :total="total"></el-pagination>
    </div>
  </div>
</template>
<script>
import { api } from '/src/api/base';
export const contractContractBatchList = api('')(
  'contract.contractBatch.list.json'
);
export default {
  data () {
    return {
      formInline: {
        f_like_name: '',
        type: ''
      },
      tableData: [],
      pageSize: 10,
      pageNumber: 1,
      total: 0,
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    seach () {
      this.pageNumber = 1
      this.loadData()
    },
    loadData () {
      const params = {
        ...this.formInline,
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
      }
      contractContractBatchList(params).then(res => {
        this.tableData = res.list
        this.total = res.total
      });

    },
    handleSizeChange (val) {
      this.pageSize = val
      this.loadData()
    },
    handleCurrentChange (val) {
      this.pageNumber = val
      this.loadData()
    },
    getMore () {
      this.$router.push({
        path: '/system/contract/contractMoreAdd'

      })
    },
    goDetail (obj) {
      this.$router.push({
        path: '/system/contract/contractMorDetail',
        query: obj,

      })
    }
  },
}
</script>
<style lang="scss" scoped>
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}
</style>